.editor{
    border: 1px solid #E5E4E2;
    border-radius: 5px;
    min-height: 30rem;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.editor:hover{
    border: 1px solid #000;
    cursor: text;
}
.editor:active{
    border: 1px solid #000;
    cursor: text;
}